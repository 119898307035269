import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import './style.css';
import Button from '../../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { getExtras, removeExtra } from '../../../store/features/extrasSlice';
import { claerBilling, getBilling, updateBilling } from '../../../store/features/billingSlice';
import { getSelectedPlans, updateExtraServices, updateExtras, removeExtra, updateService, updateServicePrice, updateExtraName, updateExtraPrice, getCustomerDetails, getSelectedType, clearPlanAndServices, updateServicePriceTotal, updateServiceQty, updateExtraPriceTotal, updateExtrasQty, updateCustomerDetails, updatePlan, updateSelectedType } from '../../../store/features/planSlice';
import { Label } from '../../../components/Label';
import { Input } from '../../../components/Input';
import { useHTTP } from '../../../hooks/useHTTP';
import Loader from '../../../components/Loader';
import { Error } from '../../../components/Error';
import { Constant } from '../../../constants';
import { Select } from '../../../components/Select';
import { numberRegex, priceRegex } from '../../../utils/regex';
import InfoModal from '../../../components/InfoModal';
import InvoicePDF from './InvoicePDF';
import { Toggle } from '../../../components/Toggle';
import { SearchInput } from '../../../components/SearchInput';
import { Delete } from '../../../assets/svg/Icons';
import ServicesModal from './ServicesModal';

const Employer = [{ 'name': 'workshop', 'uuid': '1af3dd3e-6c0b-4ebb-aabc-fd695a8bd64a' }];

function QuickOrder() {
    const { loading: isGeneratingBill, callAPI: createBill, data: responseForCreate, error: errorForCreate, success: createdBill } = useHTTP();
    const { promocode, washType, washPrice, extraPrice, dirtyWork, dirtyNotes, gst } = useSelector(getBilling);
    const { carType: selectedCarTypeFromStore, plan: planFromStore, services: servicesFromStore, extras: extrasFromStore } = useSelector(getSelectedPlans);
    const { loading: isSendingInvoice, callAPI: sendInvoice, data: responseForInvoice, error: errorForInvoice, success: sentInvoice } = useHTTP();
    const selectedType = useSelector(getSelectedType);
    // const customerDetails = useSelector(getCustomerDetails);
    const [modalInfo, setModalInfo] = useState({});
    const [notes, setNotes] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isGSTDisabled, setIsGSTDisabled] = useState(false);
    const [discountIsPercent, setDiscountIsPercent] = useState(false);
    const { state: orderDataFromReport } = useLocation();
    const orderData = useMemo(() => orderDataFromReport ? JSON.parse(orderDataFromReport?.orderData) : null, [orderDataFromReport])

    // Quick Order flow start
    const { loading: fetchingCarTypes, callAPI: getCarTypes, data: carTypes, error: fetchCarTypeError, success: fetchedCarType } = useHTTP();
    const { loading: fetchingPackages, callAPI: getPackages, data: packages, error: fetchPackagesError, success: fetchedPackages } = useHTTP();
    const { loading: fetchingPlans, callAPI: getPlans, data: plans, error: fetchplansError, success: fetchedPlans } = useHTTP();
    const { loading: fetchingServices, callAPI: getServices, data: services, error: fetchServicesError, success: fetchedServices } = useHTTP();
    const { loading: searchingCustomer, callAPI: searchCustomer, data: searchResponse, error: errorForSearch } = useHTTP();
    const customerSearchData = useMemo(() => {
        if (searchResponse?.data?.length) {
            return searchResponse?.data.map(item => item && { ...item, label: `${item?.regno}-${item?.name}` })
        }
    }, [searchResponse]);

    const [name, setName] = useState('');
    const [regoNumber, setRegoNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [customerDetails, setCustomerDetails] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedCarType, setSelectedCarType] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [extras, setExtras] = useState([]);
    const [planPrice, setPlanPrice] = useState();
    const [discount, setDiscount] = useState(0);
    const [gstPrice, updateGstPrice] = useState(0);
    const [subTotal, updateSubTotal] = useState(0);
    const [total, updateTotal] = useState(0);
    const [serviceModal, setServiceModal] = useState(false);

    // get  car types
    useEffect(() => {
        getCarTypes('car-types');
    }, []);

    // get packages, plans, and service
    useEffect(() => {
        if (fetchedCarType) {
            getPackages('packages');
            getPlans('plans');
            getServices('services');
        }
    }, [fetchedCarType, fetchCarTypeError])

    // Customer Details Search with Rego number
    useEffect(() => {
        if (regoNumber.length >= 2) {
            const timeout = setTimeout(() => {
                searchCustomer(`customers?search=${regoNumber}`, Constant.GET)
            }, 500);

            return () => clearTimeout(timeout);
        }
    }, [regoNumber]);

    // Rego number Input or Pic list
    const regoNumberHandle = useCallback((e, type) => {
        if (type === undefined) {
            const value = e?.target?.value;
            setRegoNumber(value);
            return;
        }

        if (type === 'onSelect') {
            setCustomerDetails(e);
            setRegoNumber('');
            return;
        }
    }, []);

    // On Select Car type
    const onSelectCarTyoe = (e) => {
        const { name, id, value } = e?.target;
        setSelectedCarType(value);
    }

    // Back To Search
    const backToChange = () => {
        setCustomerDetails(null);
        setSelectedPlan(null);
        setSelectedPackage(null);
        setNotes('')
    }

    // On Select Plan
    const onSelectPlan = (e) => {
        const { value } = e?.target;
        const findSelecetedPlan = plans?.data?.find(pl => pl?.uuid === value)
        setSelectedPlan(findSelecetedPlan);
        setSelectedPackage(null);
    }

    // on Select Package
    const onSelectPackage = (e) => {
        const { value } = e?.target;
        const findSelecetedPackage = packages?.data?.find(pc => pc?.uuid === value)
        setSelectedPackage(findSelecetedPackage);
        setSelectedPlan(null)
    }

    // Add Services
    const addServices = () => {
        // const payload = { id: selectedServices?.length, price: '', qty: null };
        // setSelectedServices((prev) => ([...prev, payload]));
        setServiceModal(true)
    }

    // On Select Service
    const onSelectSerivce = (e, i) => {
        const { value } = e?.target;
        let cpySelectedServices = [...selectedServices];
        cpySelectedServices[i] = services?.data?.find(ser => ser?.uuid === value);
        setSelectedServices(cpySelectedServices);
    }

    // Service Price update
    const handleServicePrice = (e, item) => {
        const { value } = e?.target;
        let priceUpdate = [];
        if (item?.uuid) {
            priceUpdate = selectedServices?.map((ss, i) => ss?.uuid === item?.uuid ? { ...ss, price: value } : ss);
        } else {
            priceUpdate = selectedServices?.map((ss, i) => i === item?.id ? { ...ss, price: value } : ss);
        }
        setSelectedServices(priceUpdate);
    }

    // Service Qty Update
    const handleServiceQty = (e, item) => {
        const { value } = e?.target;
        let qtyUpdate = [];
        if (item?.uuid) {
            qtyUpdate = selectedServices?.map((ss, i) => ss?.uuid === item?.uuid ? { ...ss, qty: value } : ss);
        } else {
            qtyUpdate = selectedServices?.map((ss, i) => i === item?.id ? { ...ss, qty: value } : ss);
        }
        setSelectedServices(qtyUpdate);
    }

    // Remove Service
    const removeService = (i) => {
        const removeService = selectedServices.filter((_, index) => index !== i);
        setSelectedServices(removeService);
    }

    // Add Extras
    const addExtras = () => {
        const payload = { id: extras?.length, others: '', base_price: null, qty: null };
        setExtras((prev) => [...prev, payload])
    }

    // Remove Extra
    const removeExtra = (i) => {
        const extraData = extras.filter((_, index) => index !== i);
        setExtras(extraData);
    }

    // Extras Name update
    const handleExtraName = useCallback((e) => {
        const { id, value } = e?.target;
        const extraData = extras?.map((ex, index) => ex?.id === Number(id) ? { ...ex, others: value } : ex);
        setExtras(extraData);
    }, [extras]);

    // Extras Price Update
    const handleExtraPrice = useCallback((e, ext) => {
        const { id, value } = e?.target;
        if (!priceRegex.test(value) && value !== '') return;
        const extraData = extras?.map((ex, index) => ex?.id === Number(id) ? { ...ex, base_price: value } : ex);
        setExtras(extraData);
    }, [extras]);

    // Extras Qty update
    const handleExtraQtyChange = useCallback((e, ext) => {
        const { id, value } = e?.target;
        if (!numberRegex.test(value) && value !== '') return;
        const extraData = extras?.map((ex, index) => ex?.id === Number(id) ? { ...ex, qty: value } : ex);
        setExtras(extraData);
    }, [extras]);

    // Quick Order flow End


    // console.log('orderData', orderData)
    useEffect(() => {
        if (orderData === null) {
            dispatch(updateExtras([]));
        } else {
            const { customers, discount, employers, gst: gstFromAPI, invoice_id, invoice_date, notes, others, packages, plans, promos_id, services, sub_total, total, uuid } = orderData;
            dispatch(updateCustomerDetails(customers));
            dispatch(updatePlan(plans[0] || packages[0] || {}));
            dispatch(updateSelectedType(plans.length ? 'plan' : 'package'));
            dispatch(updateService(services));
            dispatch(updateExtras(others));
            setNotes(notes || '');
            // dispatch(updateBilling({ name: 'gstPrice', value: Number(gst).toFixed(2) }));
            // dispatch(updateBilling({ name: 'total', value: Number(total).toFixed(2) }));
            // dispatch(updateBilling({ name: 'subTotal', value: Number(sub_total).toFixed(2) }));
            // dispatch(updateBilling({ name: 'discount', value: Number(discount).toFixed(2) }));

            setIsGSTDisabled(Number(gstFromAPI) ? true : false);
        }
    }, [orderData])

    // Selected Car price
    useEffect(() => {
        if (selectedPlan) {
            let carTypeChack = customerDetails ? customerDetails?.car_type?.uuid : selectedCarType;
            const findPlanPrice = selectedPlan?.prices?.find((pr, index) => carTypeChack === pr?.uuid);
            setPlanPrice(findPlanPrice);
            return;
        }
        if (selectedPackage) {
            let carTypeChack = customerDetails ? customerDetails?.car_type?.uuid : selectedCarType;
            const findPlanPrice = selectedPackage?.prices?.find((pr, index) => carTypeChack === pr?.uuid);
            setPlanPrice(findPlanPrice);
            return;
        }
    }, [selectedCarType, selectedPlan, selectedPackage, customerDetails]);

    // GST Price updates
    useEffect(() => {
        const discountCalc = discountIsPercent ? (subTotal - (subTotal * (Number(discount)) / 100)) : (subTotal - Number(discount));
        // With GST
        if (subTotal) {
            const payload = (discountCalc * gst) / 100;
            // dispatch(updateBilling({ name: 'gstPrice', value: payload }));
            updateGstPrice(payload);
        }

        // Without GST, GST = 0
        if (subTotal && !isGSTDisabled) {
            // dispatch(updateBilling({ name: 'gstPrice', value: 0 }));
            updateGstPrice(0);
        }
    }, [subTotal, discount, gst, isGSTDisabled, discountIsPercent]);

    // Update sub total
    useEffect(() => {
        let serviceSubTotal = 0;
        let extraSubTotal = 0;
        let pp = planPrice?.price || 0;
        if (selectedServices.length) {
            serviceSubTotal = selectedServices.reduce((acc, obj) => obj?.price ? Number(acc) + (Number(obj?.price || 0) * Number(obj?.qty || 1)) : acc, 0);
        }
        if (extras?.length) {
            extraSubTotal = extras.reduce((acc, obj) => obj?.base_price ? Number(acc) + (Number(obj?.base_price || 0) * Number(obj?.qty || 1)) : acc, 0);
        }
        const st = Number(pp) + Number(serviceSubTotal) + Number(extraSubTotal);
        // const payload = { name: 'subTotal', value: st };
        // dispatch(updateBilling(payload));
        updateSubTotal(st)
    }, [planPrice, discount, selectedServices, extras]);

    // Total price update
    useEffect(() => {
        if (subTotal) {
            const discountCalc = discountIsPercent ? (subTotal * (Number(discount) / 100)) : Number(discount);
            const payload = (subTotal + gstPrice) - Number(discountCalc);
            // dispatch(updateBilling({ name: 'total', value: payload }));
            updateTotal(payload)
        }
    }, [subTotal, gstPrice, isGSTDisabled, discount, discountIsPercent])

    useEffect(() => {
        const postInvoice = async (responseForCreate) => {
            const blob = await InvoicePDF(responseForCreate?.data);
            console.log('InvoicePDF', blob)
            const formData = new FormData();
            formData.append('invoice_id', responseForCreate?.data?.uuid);
            formData.append('invoice', blob, 'invoice.pdf');
            sendInvoice('invoice/file', Constant.POST, formData);
        }

        if (responseForCreate.status === Constant.success) {
            let msg = { ...modalInfo };
            msg = { show: true, type: Constant.success, msg: responseForCreate?.message };
            setModalInfo(msg);
            // postInvoice(responseForCreate)
        }
        if (errorForCreate !== null) {
        }
    }, [responseForCreate, errorForCreate]);

    useEffect(() => {
        if (responseForInvoice?.status === Constant.success) {
            navigate('/workshop/invoice', { state: { invoiceData: JSON.stringify(responseForCreate?.data) } });
        }
        if (errorForInvoice !== null) {
            console.log('errorForInvoice', errorForInvoice)
        }
    }, [responseForInvoice, errorForInvoice, responseForCreate])

    const handleNotes = (e) => {
        const { value } = e.target;
        setNotes(value);
    }

    const handleDiscount = useCallback((e) => {
        const { id, name, value } = e?.target;
        if (!priceRegex.test(value) && value !== '') return;
        // dispatch(updateBilling({ 'name': 'discount', value }));
        setDiscount(value)
    }, []);

    const updateDiscount = (e) => {
        const { name, checked } = e?.target;
        setDiscountIsPercent(checked);
    }

    const onChangeGST = useCallback((e) => {
        const { name, checked } = e?.target;
        setIsGSTDisabled(checked);
    }, []);

    const handlePromocode = useCallback((e) => {
        const { id, name, value } = e?.target;
        dispatch(updateBilling({ 'name': id, value }))
    }, []);

    const applyPromocode = useCallback(() => {
        console.log('Promocode API call');
    }, []);

    const goBack = useCallback(() => {
        let msg = { ...modalInfo };
        msg = { show: true, type: Constant.warning, msg: 'Are you sure you want to Exit?' };
        setModalInfo(msg);
        // navigate(-1);
    }, []);

    // Navigate to Invoice 
    const generateBill = useCallback(() => {
        // navigate('/workshop/invoice');
        const serviceErrors = selectedServices?.find((ser, i) => !ser?.hasOwnProperty('price') || ser?.price === "");
        const extraErrors = extras?.find((ext, i) => (ext?.base_price === "" || ext?.others === ""));

        if (discount > subTotal) {
            alert(`Discount can't be applied because there is no subtotal`)
            return;
        }
        if (serviceErrors === undefined && extraErrors === undefined) {
            console.log('ok. Billing Api process');
            billingProcess();
        }
    }, [selectedPlan, selectedPackage, selectedServices, extras, subTotal, discount, isGSTDisabled, gstPrice, total, customerDetails]);

    const billingProcess = async () => {
        const servicePayload = selectedServices?.map(ser => { return { 'service': ser?.uuid, 'price': ser?.price, 'qty': Number(ser?.qty) || 1 } })
        const extraPayload = extras?.map(ext => { return { 'other': ext?.others, 'price': ext?.base_price, 'qty': Number(ext?.qty) || 1 } });
        const nameForKey = selectedPlan === null ? 'package' : 'plan';

        if (!servicePayload.length && !extraPayload.length && selectedType === '') {
            alert('Not able to Generate Bill!!')
            return;
        }

        const typePayload = { [nameForKey]: planFromStore?.uuid, 'price': planPrice?.price, 'qty': 1 };
        let payload = {
            invoice_date: new Date().toLocaleDateString('en-CA'),
            due_date: new Date().toLocaleDateString('en-CA'),
            terms: '',
            sub_total: subTotal,
            gst: gstPrice,
            total: total,
            discount: Number(discount),
            promos_id: '',
            balance_due: '',
            employer: Employer[0]?.uuid,
            notes: notes,
            services: servicePayload,
            plans: [],
            packages: [],
            others: extraPayload
        }

        if (selectedPlan) {
            const typePayload = { plan: selectedPlan?.uuid, 'price': planPrice?.price, 'qty': 1 };
            payload = { ...payload, plans: [typePayload] }
        }

        if (selectedPackage) {
            const typePayload = { package: selectedPackage?.uuid, 'price': planPrice?.price, 'qty': 1 };
            payload = { ...payload, packages: [typePayload] }
        }

        // Customer
        if (customerDetails?.uuid) {
            payload = { ...payload, customer: customerDetails?.uuid }
        } else {
            const newCustomer = {
                name: name,
                phone: phoneNumber,
                car_type: selectedCarType,
                regno: regoNumber
            }
            payload = { ...payload, new_customer: newCustomer }
        }
        console.log('payload', payload);


        createBill('invoice/quick_order', Constant.POST, payload);
    }

    // Modal 
    const modalAction = useCallback((type) => {
        setModalInfo({});
        setCustomerDetails(null)
        setName('');
        setRegoNumber('')
        setPhoneNumber('');
        setNotes('');
        setSelectedCarType(null);
        setSelectedPlan(null);
        setSelectedPackage(null);
        setExtras([]);

        dispatch(clearPlanAndServices());
        dispatch(claerBilling());

    }, []);

    const serviceModalAction = useCallback((type, data) => {
        setServiceModal(false)
        if (type === Constant.close) {
            return;
        }
        setSelectedServices(data);
    }, []);

    const onChangeName = (e) => {
        setName(e?.target?.value);
    }

    const onChangePhoneNumber = (e) => {
        setPhoneNumber(e?.target?.value)
    }

    if (fetchingCarTypes || fetchingPackages || fetchingPlans || fetchingServices || isGeneratingBill || isSendingInvoice) {
        return <Loader />
    }
    return (
        <div className='page billing'>
            <div className='card-container mx-4'>
                <div className='row'>
                    <div className='col-sm-12 col-md-4 align-self-center'>
                        <div className='billing-address'>
                            {
                                customerDetails !== null &&
                                <Button className={'btn btn-sm btn-secondary mb-4'} title={'Back To Change'} action={backToChange} />
                            }
                            <h5 className='mb-4'>Billing Details</h5>
                            {
                                customerDetails !== null ?
                                    <>
                                        <Label title={customerDetails?.name} />
                                        <Label title={customerDetails?.address} />
                                        <Label title={customerDetails?.phone} />
                                        <Label title={customerDetails?.email} />

                                        <div className='my-4'>
                                            <label className='poppins-light'>Rego Number:</label>
                                            <Label title={customerDetails?.regno} />
                                        </div>

                                        <div className='my-4'>
                                            <label className='poppins-light'>Car Type:</label>
                                            <Label title={customerDetails?.car_type?.name} />
                                        </div>

                                        <div className='my-4 w-50'>
                                            <label className='poppins-light'>Employer</label>
                                            <Select value={Employer[0]?.uuid} options={Employer} onChange={() => console.log('change employer')} />
                                        </div>
                                        <div className='my-4 w-75'>
                                            <Label title={'Notes'} />
                                            <textarea name='notes' id='notes' className='form-control' value={notes} onChange={handleNotes} />
                                        </div>
                                    </>

                                    :
                                    <>
                                        <div className='my-4'>
                                            <label className='poppins-light'>Rego Number:</label>
                                            <SearchInput
                                                name='regoNumber'
                                                value={regoNumber}
                                                options={customerSearchData}
                                                onchange={regoNumberHandle}
                                            />
                                        </div>

                                        <div className='my-4'>
                                            <label className='poppins-light'>Phone Number:</label>
                                            <input type='text' className='form-control' onChange={onChangePhoneNumber} />
                                        </div>
                                        <div className='my-4'>
                                            <label className='poppins-light'>Car Type:</label>
                                            <Select value={selectedCarType} options={carTypes?.data} onChange={onSelectCarTyoe} />
                                        </div>
                                        <div className='my-4'>
                                            <label className='poppins-light'>Name:</label>
                                            <input type='text' className='form-control' onChange={onChangeName} />
                                        </div>

                                        {/* <div className=''>
                                            <Button className={'btn btn-p'} title={'Apply'} action={() => console.log('Create customer')} />
                                        </div> */}
                                    </>
                            }


                        </div>
                    </div>

                    <div className={`col-sm-12 col-md-8 `}>
                        <table className='table'>
                            <thead className='heading-wrap'>
                                <th>Services</th>
                                <th>Rate</th>
                                <th className='text-center'>Qty</th>
                                <th className='text-end'>Price</th>
                                <th className='text-end'>Action</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Plans:
                                        <Select value={selectedPlan?.uuid} options={plans?.data} onChange={onSelectPlan} />
                                    </td>
                                    <td className='ps-3'>{selectedPlan ? planPrice?.price : '00'}</td>
                                    <td className='text-center'>1</td>
                                    <td className='text-end'>{selectedPlan ? Number(planPrice?.price).toFixed(2) : '00.00'}</td>
                                    <td className='text-end'></td>
                                </tr>
                                <tr>
                                    <td>
                                        Packages:
                                        <Select value={selectedPackage?.uuid} options={packages?.data} onChange={onSelectPackage} />
                                    </td>
                                    <td className='ps-3'>{selectedPackage ? planPrice?.price : '00'}</td>
                                    <td className='text-center'>1</td>
                                    <td className='text-end'>{selectedPackage ? Number(planPrice?.price).toFixed(2) : '00.00'}</td>
                                    <td className='text-end'></td>
                                </tr>
                                {
                                    selectedServices?.map((item, i) => {
                                        const { id, uuid } = item;
                                        const qty = item?.qty === null ? 1 : item?.qty;
                                        const price = item?.price === null ? 0 : item?.price;
                                        const totalPrice = (Number(price) * Number(qty)) || 0;
                                        return (
                                            <tr key={i} className='align-bottom'>
                                                <td>
                                                    Services:
                                                    <Select value={uuid} options={services?.data} onChange={(e) => onSelectSerivce(e, i)} />
                                                </td>
                                                <td className='align-bottom'>
                                                    <Input placeholder='Price' code={i} classname={`f-14`} value={price} maxLength={8} onChange={(e) => handleServicePrice(e, item)} />
                                                </td>
                                                <td className='text-center align-bottom'>
                                                    <Input placeholder='Qty' code={i} classname={`f-14 text-center`} value={qty} maxLength={5} onChange={(e) => handleServiceQty(e, item)} />
                                                </td>
                                                <td className='text-end'>{totalPrice.toFixed(2)}</td>
                                                <td className='text-end'>
                                                    <button className='btn' onClick={() => removeService(i)}>
                                                        <Delete />
                                                    </button>
                                                    {/* <Button className={'btn-secondary'} title={'-'} action={() => removeService(i)} /> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }


                                {
                                    extras?.map((item, index) => {
                                        const { id, others = '', inv_price = '' } = item;
                                        const qty = item?.qty === null ? 1 : item?.qty;
                                        const base_price = item?.base_price === null ? '' : item?.base_price;
                                        const totalPrice = (Number(base_price) * Number(qty)) || 0;

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Input placeholder='Enter Service Name' code={index} value={others} classname={`f-14 ${others === '' && 'error'}`} maxLength={250} onChange={handleExtraName} />
                                                </td>
                                                <td>
                                                    <Input placeholder='Price' code={index} classname={`f-14 ${base_price === '' && 'error'}`} value={base_price} maxLength={8} onChange={(e) => handleExtraPrice(e, item)} />
                                                </td>
                                                <td>
                                                    <Input placeholder='Qty' code={index} classname={`f-14 text-center ${qty === '' && 'error'}`} value={qty} maxLength={5} onChange={(e) => handleExtraQtyChange(e, item)} />
                                                </td>
                                                <td className='text-end'>{totalPrice.toFixed(2)}</td>
                                                <td className='text-end'>
                                                    <button className='btn' onClick={() => removeExtra(index)}>
                                                        <Delete />
                                                    </button>
                                                    {/* <Button className={'btn-secondary'} title={'-'} action={() => removeExtra(index)} /> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                <tr>
                                    <td>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center'>
                                                <Button className={'ms-2 btn-p'} title={'+'} action={() => addServices()} />
                                                <div className='ms-2 text-nowrap'>
                                                    <Label title={'Add Services'} />
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Button className={'ms-2 btn-p'} title={'+'} action={() => addExtras()} />
                                                <div className='ms-2 text-nowrap'>
                                                    <Label title={'Add Extras'} />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr className='heading-wrap'>
                                    <td><Label title={'Sub Total'} /></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'><Label title={`$ ${subTotal.toFixed(2)}`} /></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td><Label title={'Discount'} /></td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <span>$</span>
                                            <div className='mx-1'>
                                                <Toggle intialValue={discountIsPercent} onToggle={updateDiscount} />
                                            </div>
                                            <span>%</span>
                                        </div>
                                    </td>

                                    <td className='text-end'>
                                        <Input placeholder='' code={'discount'} classname='f-12 text-end' value={discount} maxLength={8} onChange={handleDiscount} />
                                    </td>
                                    <td className='text-end'>
                                        <Label title={`$ ${discountIsPercent ? (subTotal * (Number(discount) / 100)).toFixed(2) : Number(discount).toFixed(2)}`} />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><Label title={`GST (${gst}%)`} /></td>
                                    <td className='ps-3'> <Toggle intialValue={isGSTDisabled} onToggle={onChangeGST} /></td>
                                    <td></td>
                                    <td className='text-end'><Label title={`$ ${Number(gstPrice).toFixed(2)}`} /></td>
                                    <td></td>
                                </tr>
                                <tr className='heading-wrap'>
                                    <td><Label title={'Total'} /></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'><Label title={`$ ${Number(total).toFixed(2)}`} /></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td className='py-4'>Promocode: </td>
                                    <td className='w-50'>
                                        <Input placeholder='Enter Your Promocode ' code={'promocode'} classname='f-12 w-100' value={promocode} disabled onChange={handlePromocode} />
                                    </td>
                                    <td>
                                        <Button title={'Apply'} className='btn-primary disabled' action={() => applyPromocode()} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='mt-3 d-flex justify-content-end'>
                            <Button title={'Close'} className='btn-secondary mx-4' action={goBack} />
                            {
                                orderData?.uuid ?
                                    <Button title={'Update Order'} className='btn-p' action={generateBill} />
                                    :
                                    <Button title={'Create Order'} className='btn-p' action={generateBill} />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                modalInfo?.show &&
                <InfoModal continueBtnText='Continue' {...modalInfo} icon={true} continueBtn={true} action={(type) => modalAction(type)} />
            }

            {
                serviceModal &&
                <ServicesModal servicesData={services?.data} selectedServicesFromOrderPage={selectedServices} action={serviceModalAction} />
            }
        </div>
    );
}

export default QuickOrder; 