import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import { Constant } from "../../../constants";
import { useHTTP } from "../../../hooks/useHTTP";

const ServicesModal = ({ servicesData = [], selectedServicesFromOrderPage = [], action }) => {
    const [selectedServices, setSelectedServices] = useState(selectedServicesFromOrderPage);

    const onClickService = (service) => {
        const findService = selectedServices?.find(ser => ser?.uuid === service?.uuid);
        if (findService === undefined) {
            setSelectedServices((prev) => ([...prev, service]))
        } else {
            const updatedService = selectedServices?.filter(ser => ser?.uuid !== service?.uuid);
            setSelectedServices(updatedService);
        }
    }

    return (
        <>
            <div className="modal fade show d-block contact-modal service-modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content rounded-0">
                        <div className="modal-header justify-content-center border-0">
                            <div className="d-flex">
                                {/* <h1 className="modal-title fs-5 text-center" id="staticBackdropLabel">{'Select services'}</h1> */}

                            </div>
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close" onClick={() => action(Constant.close)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                {
                                    servicesData?.map((item, index) => {
                                        const findActive = selectedServices.findIndex(ser => ser.uuid === item.uuid);

                                        return (
                                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 my-2">
                                                <button className={`btn w-100 h-100 service-btn ${findActive !== -1 && 'active'}`} onClick={() => onClickService(item, index)} >
                                                    {item?.name}
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                        <div className="modal-footer justify-content-end">
                            <Button title={'Close'} className={'btn btn-secondary'} action={() => action(Constant.close)} />
                            <Button title={'Proceed'} className={'btn btn-p'} action={() => action(Constant.proceed, selectedServices)} />
                        </div>
                    </div>
                </div>
            </div >
            <div className="modal-backdrop fade show"></div>
        </>
    )
}

export default ServicesModal;
