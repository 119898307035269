
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, PDFViewer, Font, pdf } from '@react-pdf/renderer';
import React, { useEffect, useMemo, useState } from 'react';
import './style.css';
import { dateFormat } from '../../../utils/dateFormat';
const logo = require('../../../assets/images/logo.png');

Font.register({
    family: 'Poppins',
    fonts: [
        {
            src: require('../../../assets/fonts/Poppins-Regular.ttf') // Regular
        },
        {
            src: require('../../../assets/fonts/Poppins-Light.ttf'),
            fontWeight: 'light',
        },
        {
            src: require('../../../assets/fonts/Poppins-Bold.ttf'),
            fontWeight: 'bold', // Bold
        }
    ],
});


// Create styles
const styles = StyleSheet.create({
    poppinsRegular: {
        fontFamily: 'Poppins',
    },
    poppinsLight: {
        fontFamily: 'Poppins',
        fontWeight: 'light',
    },
    poppinsBold: {
        fontFamily: 'Poppins',
        fontWeight: 'bold',
    },

    page: {
        padding: 30,
    },
    section: {
        marginBottom: 10,
        flexDirection: 'row',
    },
    table: {
        display: "table",
        width: "100%",
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderRightWidth: 0,
        // borderBottomWidth: 0,
        border: 0
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        // width: "25%",
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderLeftWidth: 0,
        // borderTopWidth: 0,
        padding: 5,
    },
    tableCellHeader: {
        fontSize: 5.5,
        color: '#0086b3',
        textAlign: 'left'
    },
    tableCell: {
        fontSize: 5,
    },
    logo: {
        height: '80px',
        width: '80px'
    },
    title: {
        fontSize: '5px',
        textTransform: 'uppercase'
    },
    text2: {
        fontSize: '6px'
    },
    text3: {
        fontSize: '5px',
    },
    textEnd: {
        textAlign: 'right',
        alignItems: 'flex-end'
    },
    w90: {
        width: '90%',
    },
    w10: {
        width: '10%'
    },
    w15: {
        width: '15%'
    },
    w20: {
        width: '20%'
    },
    w45: {
        width: '45%'
    },
    w50: {
        width: '50%'
    },
    bg: {
        backgroundColor: '#0086b320'
    },
    textGreen: {
        color: '#0086b3'
    },
    borderBottom: {
        borderBottomWidth: 0.5,
        borderStyle: 'dashed',
        borderBottomColor: '#ddd'
    }
});


// Create Document Component
const InvoicePDF = async (data) => {
    // const [billindData, setBillingData] = useState([]);
    // useEffect(() => {
    //     if (data !== null) {
    //         let mapData = [];

    //         // const planData = billingResponse?.packages && { 'name': planFromStore?.name, 'price': 100 };
    //         const packageData = packages?.map(pc => { return { 'name': pc?.name, 'price': pc?.inv_price, 'qty': pc?.qty, 'base_price': pc?.base_price } });
    //         const planData = plans?.map(pl => { return { 'name': pl?.name, 'price': pl?.inv_price, 'qty': pl?.qty, 'base_price': pl?.base_price } });
    //         const servicesData = services?.map(ser => { return { 'name': ser?.name, 'price': ser?.inv_price, 'qty': ser?.qty, 'base_price': ser?.base_price } });
    //         const extrasData = others?.map(ext => { return { 'name': ext?.others, 'price': ext?.inv_price, 'qty': ext?.qty, 'base_price': ext?.base_price } });

    //         // mapData.push(planData);
    //         mapData.push([...packageData]);
    //         mapData.push([...planData]);
    //         mapData.push([...servicesData]);
    //         mapData.push([...extrasData]);

    //         const mappedData = mapData.flat();
    //         setBillingData(mappedData)
    //     }

    // }, [data]);

    const { balance_due, due_date, customers, discount, created_at, gst, invoice_date, invoice_id, notes, others, packages, plans, promos_id, services, sub_total, terms, total } = data;

    let mapData = [];

    // const planData = billingResponse?.packages && { 'name': planFromStore?.name, 'price': 100 };
    const packageData = packages?.map(pc => { return { 'name': pc?.name, 'price': pc?.inv_price, 'qty': pc?.qty, 'base_price': pc?.base_price } });
    const planData = plans?.map(pl => { return { 'name': pl?.name, 'price': pl?.inv_price, 'qty': pl?.qty, 'base_price': pl?.base_price } });
    const servicesData = services?.map(ser => { return { 'name': ser?.name, 'price': ser?.inv_price, 'qty': ser?.qty, 'base_price': ser?.base_price } });
    const extrasData = others?.map(ext => { return { 'name': ext?.others, 'price': ext?.inv_price, 'qty': ext?.qty, 'base_price': ext?.base_price } });

    // mapData.push(planData);
    mapData.push([...packageData]);
    mapData.push([...planData]);
    mapData.push([...servicesData]);
    mapData.push([...extrasData]);

    const mappedData = mapData.flat();

    const pdfFile = (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View>
                        <Image src={logo} style={styles.logo} />
                    </View>

                    <View style={[styles.table, styles.textEnd, styles.w90]}>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCol, { alignItems: 'flex-end' }]}>
                                <Text style={[styles.title, styles.poppinsRegular]}>
                                    JS Car Detailing and cleaning group ltd
                                </Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>Portico Plaza</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>G1-G2, 17-19 Aurelia St</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>Toongabbie NSW 2146</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>0469 053 683</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>js.detailing30@gmail.com</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>ABN 62676778653</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={[styles.table]}>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.title, styles.poppinsRegular]}>
                                    INVOICE TO
                                </Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.name}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.address}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.phone}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.email}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.abn_number ? `ABN ${customers?.abn_number}` : ''}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.table]}>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.title, styles.poppinsRegular]}>
                                    SHIP TO
                                </Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.name}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.address}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.phone}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.email}</Text>
                                <Text style={[styles.text3, styles.poppinsLight]}>{customers?.abn_number ? `ABN ${customers?.abn_number}` : ''}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.table]}>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCol, styles.w20, { padding: 0 }]}>
                                <Text style={[styles.title, styles.poppinsRegular]}>
                                    INVOICE:
                                </Text>
                            </View>
                            <View style={[styles.tableCol, { paddingHorizontal: 4, paddingVertical: 0 }]}>
                                <Text style={[styles.title, styles.poppinsLight]}>
                                    {invoice_id}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={[styles.tableCol, styles.w20, { padding: 0 }]}>
                                <Text style={[styles.title, styles.poppinsRegular]}>
                                    DATE:
                                </Text>
                            </View>
                            <View style={[styles.tableCol, { paddingHorizontal: 4, paddingVertical: 0 }]}>
                                <Text style={[styles.title, styles.poppinsLight]}>
                                    {dateFormat(invoice_date)}
                                </Text>
                            </View>
                        </View>
                    </View>

                </View>

                <View style={[styles.table]}>
                    {/* Table Header */}
                    <View style={[styles.tableRow, styles.bg]}>
                        <View style={[styles.tableCol, styles.w10]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular]}>S.No</Text>
                        </View>
                        <View style={[styles.tableCol, styles.w45]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular]}>Package/ Plan/ Service</Text>
                        </View>
                        <View style={[styles.tableCol, styles.w15]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular]}>Rate</Text>
                        </View>
                        <View style={[styles.tableCol, styles.w10]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular]}>QTY</Text>
                        </View>
                        <View style={[styles.tableCol, styles.w20]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular, styles.textEnd]}>Amount</Text>
                        </View>
                    </View>
                    {/* Table Rows */}

                    {
                        mappedData?.map((item, index) => {
                            const { name, price, qty, inv_price, base_price } = item;
                            return (
                                <View key={index} style={[styles.tableRow, styles.borderBottom]}>
                                    <View style={[styles.tableCol, styles.w10]}>
                                        <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{index + 1}</Text>
                                    </View>
                                    <View style={[styles.tableCol, styles.w45]}>
                                        <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{name} </Text>
                                    </View>
                                    <View style={[styles.tableCol, styles.w15]}>
                                        <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{Number(base_price).toFixed(2)}</Text>
                                    </View>
                                    <View style={[styles.tableCol, styles.w10]}>
                                        <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{qty}</Text>
                                    </View>
                                    <View style={[styles.tableCol, styles.w20, styles.textEnd]}>
                                        <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{Number(price).toFixed(2)}</Text>
                                    </View>
                                </View>
                            )
                        })
                    }
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: '50%' }}></View>
                    <View style={[styles.table, styles.w50]}>
                        <View style={[styles.tableRow, { justifyContent: 'space-between' }]}>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsRegular]}>SUB TOTAL</Text>
                            </View>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{Number(sub_total).toFixed(2)}</Text>
                            </View>
                        </View>

                        <View style={[styles.tableRow, { justifyContent: 'space-between' }]}>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsRegular]}>GST</Text>
                            </View>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{Number(gst).toFixed(2)}</Text>
                            </View>
                        </View>

                        <View style={[styles.tableRow, styles.borderBottom, { justifyContent: 'space-between' }]}>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsRegular]}>DISCOUNT</Text>
                            </View>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{Number(discount).toFixed(2)}</Text>
                            </View>
                        </View>

                        <View style={[styles.tableRow, { justifyContent: 'space-between' }]}>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsBold, styles.text3]}>TOTAL</Text>
                            </View>
                            <View style={[styles.tableCol]}>
                                <Text style={[styles.tableCell, styles.poppinsBold, styles.text3]}>A$ {Number(total).toFixed(2)}</Text>
                            </View>
                        </View>
                    </View>
                </View>


                <View style={{ marginVertical: 8 }}>
                    <Text style={[styles.textGreen, styles.text2, styles.poppinsRegular]}>Notes</Text>
                    <View style={[styles.bg, { padding: 8 }]}>
                        <Text style={[styles.text3, styles.poppinsLight]}>Rego Number: {customers?.regno}</Text>
                        <Text style={[styles.text3, styles.poppinsLight]}>Car Type: {customers?.car_type?.name}</Text>
                    </View>
                </View>

                <Text style={[styles.textGreen, styles.text2, styles.poppinsRegular]}>BAS SUMMARY</Text>
                <View style={[styles.table]}>
                    {/* Table Header */}
                    <View style={[styles.tableRow, styles.bg, { justifyContent: 'flex-end' }]}>
                        <View style={[styles.tableCol, styles.w10]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular]}>Rate</Text>
                        </View>
                        <View style={[styles.tableCol, styles.w15]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular]}>GST</Text>
                        </View>
                        <View style={[styles.tableCol, styles.textEnd]}>
                            <Text style={[styles.tableCellHeader, styles.poppinsRegular]}>NET</Text>
                        </View>

                    </View>
                    {/* Table Rows */}

                    <View style={[styles.tableRow, { justifyContent: 'flex-end' }]}>
                        <View style={[styles.tableCol, styles.w10]}>
                            <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>GST 10% </Text>
                        </View>
                        <View style={[styles.tableCol, styles.w15]}>
                            <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>
                                {Number(gst).toFixed(2)}</Text>
                        </View>
                        <View style={[styles.tableCol, styles.textEnd]}>
                            <Text style={[styles.tableCell, styles.poppinsLight, styles.text3]}>{Number(sub_total).toFixed(2)}</Text>
                        </View>
                    </View>
                </View>

                <View style={{ marginVertical: 8 }}>
                    <View style={[styles.bg, { padding: 8 }]}>
                        <Text style={[styles.text3, styles.poppinsLight]}>BSB Number: 062534</Text>
                        <Text style={[styles.text3, styles.poppinsLight]}>Account Number: 10709915</Text>
                        <Text style={[styles.text3, styles.poppinsLight]}>Account Name: JS Car Detailing and Cleaning Group PTY LTD</Text>
                        <Text style={[styles.text3, styles.poppinsLight]}>Bank: Commonwealth</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
    const blob = await pdf(pdfFile).toBlob();
    return blob;
}

// const InvoicePage = () => (
//     <div>
//         <PDFDownloadLink document={<InvoicePDF />} fileName="table-example.pdf">
//             {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
//         </PDFDownloadLink>
//         <PDFViewer style={{ width: '100%', minHeight: '100vh' }}>
//             <InvoicePDF />
//         </PDFViewer>
//     </div>
// );

export default InvoicePDF;
