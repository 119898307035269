// Admin.js
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Plans from '../pages/workshop/plan/Plans';
import UserForm from '../pages/workshop/user/UserForm';
import ExistingUser from '../pages/workshop/user/ExistingUser';
import BillReports from '../pages/workshop/reports/BillReports';
import Billing from '../pages/workshop/billing/Billing';
import Invoice from '../pages/workshop/invoice/Invoice';
import Header from '../components/Header';
import Extras from '../pages/workshop/plan/Extras';
import Notes from '../pages/workshop/order/Notes';
import QuickOrder from '../pages/workshop/billing/QuickOrder';

function WorkshopRoutes() {
    const location = useLocation();
    return (
        <>
            {location?.pathname !== '/workshop/invoice' &&
                <Header />
            }
            <Routes>
                <Route path="/plans" element={<Plans />} />
                <Route path="/newUser" element={<UserForm />} />
                <Route path="/editUser" element={<UserForm />} />
                <Route path="/existingUser" element={<ExistingUser />} />
                <Route path="/billReports" element={<BillReports />} />
                <Route path="/extras" element={<Extras />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/orderNotes" element={<Notes />} />
                <Route path="/quickOrder" element={<QuickOrder />} />
            </Routes>
        </>
    );
}

export default WorkshopRoutes;
